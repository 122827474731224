@import './globalVars'

/*Components*/
.Button
    background-color: $white
    color: $primaryColor
    cursor: pointer
    width: 100%
    font-weight: bold
    &:hover
        background-color: $primaryColor
        color: $white
    &:active
        background-color: $white
        color: $primaryColor

.ButtonWrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    padding-right: 25%
    padding-left: 25%
    .Button
        background-color: $white
        color: $primaryColor
        cursor: pointer
        width: 100%
        font-weight: bold
        &:hover
            background-color: $primaryColor
            color: $white
        &:active
            background-color: $white
            color: $primaryColor

.NavigatorWrapper
    background: rgb(217,217,217)
    background: -moz-linear-gradient(270deg, rgba(217,217,217,1) 0%, rgba(242,242,242,1) 40%)
    background: -webkit-linear-gradient(270deg, rgba(217,217,217,1) 0%, rgba(242,242,242,1) 40%)
    background: linear-gradient(270deg, rgba(217,217,217,1) 0%, rgba(242,242,242,1) 40%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#f2f2f2",GradientType=1)
    height: 100%
    position: fixed

.ContentWrapper
    transition-duration: 0.5s
    display: flex
    flex-direction: column
    flex: 1
    .Content
        padding: 30px

.flexContainer
    display: flex
    position: absolute
    height: 100%   
    width: 100% 

    
.FormWrapper
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    > div
        width: 50%
    
    

/*Helpers*/
a
    cursor: pointer
    font-weight: bold

.hide
    visibility: hidden
.show
    visibility: visible

