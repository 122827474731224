@import '../../global/globalVars'
    
.SearchBarWrapper
    width: 60%

.TopOptions
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 40%
    margin-bottom: 20px
.container
    display: flex
    flex-direction: column
    height: 100%
    width: 100%
    .TopOptions
        flex: 0.3
    .TableWrapper
        flex: 0.7
        margin-bottom: 20px
        display: flex
        .Table
            flex: 1

.infoView
    margin-right: 5px
    Input
        margin-bottom: 5px
    strong
        color: $primaryColor
        