@import '../../global/globalVars'

.mainView
    display: flex
    flex-direction: column
    .infoView
        display: flex
        justify-content: space-around
        align-items: center
        width: 50%
        h1
            margin: 0
            padding: 0
        p
            margin: 0
            padding: 0
            font-size: 1.2rem
    .dataView
        display: flex
        margin-top: 40px
        justify-content: space-around
        .dataWrapper
            flex: 0.2
            div
                margin-top: 20px
            h4
                color: $primaryColor
        .listWrapper
            flex: 0.6