@import '../../global/globalVars'
    
.container 
    display: flex
    width: 100%
    flex-direction: row
    height: 100%
    position: absolute
    @media screen and (max-width: $small)
        flex-direction: column
    .leftWrapper
        align-items: center
        flex-direction: column
        justify-content: center
        flex: 0.6
        display: flex
        background: rgb(217,217,217)
        background: -moz-linear-gradient(0deg, rgba(217,217,217,1) 0%, rgba(242,242,242,1) 58%)
        background: -webkit-linear-gradient(0deg, rgba(217,217,217,1) 0%, rgba(242,242,242,1) 58%)
        background: linear-gradient(0deg, rgba(217,217,217,1) 0%, rgba(242,242,242,1) 58%)
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#f2f2f2",GradientType=1)
        transition-duration: 0.5s
        &:hover
            flex: 0.61
        .wrapper, .registerWrapper
            width: 70%
            flex: 0.3
            display: flex
            flex-direction: column
            align-items: center
            justify-content: space-around
            transition: (opacity 0.5s ease-in-out)    
            .inputContainer
                width: 80%
            .rememberContainer
                display: flex
                width: 100%
                justify-content: space-between
                align-items: center
            .registerContainer
                display: flex
                width: 100%
                justify-content: center
                align-items: center
                p, a
                    font-size: 20px
                p
                    margin-right: 10px
        .wrapper.hide, .registerWrapper.hide
            position: absolute
            opacity: 0
        .wrapper.show, .registerWrapper.show
            position: relative
            opacity: 1
    .rightWrapper
        flex: 0.4
        display: flex
        align-items: center
        justify-content: center
        
        background: rgb(195,89,0)
        background: -moz-linear-gradient(-45deg, rgba(195,89,0,1) 0%, rgba(245,130,31,1) 51%, rgba(244,161,89,1) 100%)
        background: -webkit-linear-gradient(-45deg, rgba(195,89,0,1) 0%, rgba(245,130,31,1) 51%, rgba(244,161,89,1) 100%)
        background: linear-gradient(-45deg, rgba(195,89,0,1) 0%, rgba(245,130,31,1) 51%, rgba(244,161,89,1) 100%)
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c35900",endColorstr="#f4a159",GradientType=1)
        transition-duration: 0.5s
        
        &:hover
            flex:0.41
        .wrapper
            flex: 0.6
            color: $white
            display: flex
            flex-direction: column
            align-items: center
            text-align: center
            @media screen and (max-width: $small)
                flex: 0.8
                justify-content: center
                
            p
                font-size: 20px


        