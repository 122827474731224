@import '../../global/globalVars'

.FormWrapper
    width: 100%
    align-items: center
    justify-content: center
    display: flex
    flex-direction: column
    Input
        margin-bottom: 10px
        width: 70%
    .RadioGroup
        margin-bottom: 10px
        width: 70%
    h2
        color: $primaryColor
    Button
        width: 50%
        margin-bottom: 10px
    .signatureWrapper
        background-color: $lightGray
        width: 70%
        border-radius: 8px
        margin-bottom: 10px
        margin-top: 10px
    
.radioGroupLabel
    padding: (8px 2px 8px 10px)
    display: inline-block
    vertical-align: middle
