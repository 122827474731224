@import '../../global/globalVars'
    
.SearchBarWrapper
    width: 60%
.ButtonWrapper
    height: 100%
.TopOptions
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 40%
    margin-bottom: 20px

.TopIcon
    color: $primaryColor
    font-size: 2rem